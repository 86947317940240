import { useStaticQuery, graphql } from "gatsby";

const useProjectsAll = () => {
  const data = useStaticQuery(graphql`
    {
      allWpIsrProject(sort: { fields: date, order: DESC }) {
        nodes {
          id
          title
          uri
          date(formatString: "MMMM DD, YYYY")
          author {
            node {
              name
            }
          }
          projectsCategories {
            nodes {
              name
              uri
              databaseId
            }
          }
          projectSingle {
            country
            crisisName {
              ... on WpCrisis {
                databaseId
                title
                uri
              }
            }
            projectStatus
          }
          pageExcerpt {
            pageExcerpt
          }
        }
      }
    }
  `);
  return data.allWpIsrProject.nodes;
};

export default useProjectsAll;
