import React from "react";
import uniqueId from "lodash/uniqueId";
import useFeaturedProjects from "../../../../hooks/useProjects/useFeaturedProjects";
import ProjectCard from "../../../ProjectCard/ProjectCard";
import Spacer from "../../../Spacer/Spacer";

const FeaturedProjectsSection = () => {
  const { heading, selectFeaturedProjects } = useFeaturedProjects();
  return (
    <div className="container">
      <Spacer mobileSize={50} size={100} />
      {heading && <h2>{heading}</h2>}
      <hr size={1} className="divider" />
      <Spacer mobileSize={10} size={10} />
      <div className="featured-projects">
        {((selectFeaturedProjects && selectFeaturedProjects.length === 0) ||
          selectFeaturedProjects === null) && (
          <h3 className="no-data">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-info-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
            </svg>
            There are no featured projects
          </h3>
        )}
        {selectFeaturedProjects &&
          selectFeaturedProjects.map((project) => (
            <ProjectCard
              ProjectData={project.selectProject[0]}
              key={uniqueId("featured_proj_")}
            />
          ))}
      </div>
      <Spacer mobileSize={40} size={70} />
      <hr size={1} className="divider grey" />
    </div>
  );
};

export default FeaturedProjectsSection;
