import React from "react";
import Layout from "../components/layout/layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ProjectsListing from "../components/ProjectsListing/ProjectsListing";

const ProjectsArchive = () => {
  return (
    <Layout>
      <Header />
      <ProjectsListing />
      <Footer />
    </Layout>
  );
};

export default ProjectsArchive;
