import { useStaticQuery, graphql } from "gatsby";

const useCrisisNames = () => {
  const data = useStaticQuery(graphql`
    {
      allWpCrisis {
        nodes {
          title
          databaseId
        }
      }
    }
  `);

  return data.allWpCrisis.nodes.map((crisis) => ({
    label: crisis.title,
    value: crisis.databaseId,
  }));
};

export default useCrisisNames;
