import React, { useState, useEffect } from "react";
import { useFormik, FormikProvider, Field } from "formik";
import PropTypes from "prop-types";
import IsrInput from "../../../IsrInput/IsrInput";
import countriesData from "../../../../assets/json/countries.json";
import useCrisisNames from "../../../../hooks/useProjects/useCrisisNames";
import useProjectCategoriesAll from "../../../../hooks/useProjects/useProjectCategoriesAll";

const FilteringHeading = ({ setFilters, filteringOptions }) => {
  const crisisNameOptions = useCrisisNames();
  const researchCategories = useProjectCategoriesAll();

  const filteringForm = useFormik({
    initialValues: {
      searchByName: filteringOptions.searchByName
        ? filteringOptions.searchByName
        : "",
      crisisName: filteringOptions.crisisName.value
        ? filteringOptions.crisisName
        : { label: "All Crisis", value: false },
      projectStatus: filteringOptions.projectStatus.value
        ? filteringOptions.projectStatus
        : { label: "All Statuses", value: false },
      researchCategory: filteringOptions.researchCategory.value
        ? filteringOptions.researchCategory
        : { label: "All Categories", value: false },
      country: filteringOptions.country.value
        ? filteringOptions.country
        : { label: "All Countries", value: false },
      sortBy: { label: "Date newest", value: "DESC" },
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    setFilters(filteringForm.values);
  }, [
    filteringForm.values.country,
    filteringForm.values.crisisName,
    filteringForm.values.projectStatus,
    filteringForm.values.researchCategory,
    filteringForm.values.sortBy,
  ]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setFilters(filteringForm.values);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [filteringForm.values.searchByName]);

  const countries = countriesData.map((country) => {
    return { label: country.name, value: country.name };
  });
  const [filtersOpen, setFilterOpen] = useState(false);

  return (
    <div className="container">
      <h2>All Projects</h2>
      <FormikProvider value={filteringForm}>
        <form>
          <div className="search-field">
            <Field
              name="searchByName"
              id="searchByName"
              component={IsrInput}
              placeholder="Search projects by name"
              variant="text"
            />
            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
              <path
                d="m19.512 17.155-4.231-4.23a8.276 8.276 0 0 0 1.386-4.592C16.667 3.738 12.928 0 8.333 0S0 3.738 0 8.333c0 4.595 3.738 8.334 8.333 8.334a8.276 8.276 0 0 0 4.591-1.386l4.231 4.23a1.666 1.666 0 1 0 2.357-2.356zM2.5 8.333A5.84 5.84 0 0 1 8.333 2.5a5.84 5.84 0 0 1 5.834 5.833 5.84 5.84 0 0 1-5.834 5.834A5.84 5.84 0 0 1 2.5 8.333z"
                fill="#000"
                fillRule="nonzero"
              />
            </svg>
          </div>
          <button
            className="mobile-filters-toggle"
            type="button"
            onClick={() => setFilterOpen(!filtersOpen)}
          >
            Filters
          </button>
          <div className={`filters ${filtersOpen ? "isOpen" : ""}`}>
            <Field
              name="crisisName"
              id="crisisName"
              variant="select"
              classNamePrefix="isr"
              component={IsrInput}
              placeholder="Crisis Name"
              options={[
                { label: "All Crisis", value: false },
                ...crisisNameOptions,
              ]}
            />
            <Field
              name="projectStatus"
              id="projectStatus"
              variant="select"
              classNamePrefix="isr"
              component={IsrInput}
              placeholder="Project Status"
              options={[
                { label: "All Statuses", value: false },
                { label: "Preparatory", value: 1 },
                { label: "Declared", value: 2 },
                { label: "Proposal submissions now closed", value: 3 },
              ]}
            />
            <Field
              name="researchCategory"
              id="researchCategory"
              variant="select"
              classNamePrefix="isr"
              component={IsrInput}
              placeholder="Research category"
              options={[
                { label: "All Categories", value: false },
                ...researchCategories,
              ]}
            />
            <Field
              name="country"
              id="country"
              variant="select"
              classNamePrefix="isr"
              component={IsrInput}
              placeholder="Country"
              options={[{ label: "All Countries", value: false }, ...countries]}
            />
            <div className="sorting-div">
              <p className="sort-by-label">Sort by</p>
              <Field
                name="sortBy"
                id="sortBy"
                variant="select"
                classNamePrefix="isr"
                component={IsrInput}
                placeholder="Sort by"
                options={[
                  { label: "Date newest", value: "DESC" },
                  { label: "Date oldest", value: "ASC" },
                ]}
              />
            </div>
          </div>
        </form>
      </FormikProvider>
    </div>
  );
};

FilteringHeading.propTypes = {
  setFilters: PropTypes.func.isRequired,
  filteringOptions: PropTypes.shape({
    searchByName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    crisisName: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
        ]),
      }),
      PropTypes.bool,
    ]),
    projectStatus: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
        ]),
      }),
      PropTypes.bool,
    ]),
    researchCategory: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
        ]),
      }),
      PropTypes.bool,
    ]),
    country: PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
          PropTypes.bool,
        ]),
      }),
      PropTypes.bool,
    ]),
  }).isRequired,
};

export default FilteringHeading;
