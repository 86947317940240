import React from "react";
import PropTypes from "prop-types";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import { makeStyles } from "@material-ui/core/styles";
import "./ProjectsListingPagination.scss";

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-textPrimary.Mui-selected": {
      backgroundColor: "#70e8cb",
      color: "#000000",
    },
    "& .MuiPaginationItem-textPrimary.Mui-selected:hover, & .MuiPaginationItem-textPrimary.Mui-selected.Mui-focusVisible":
      {
        backgroundColor: "#70e8cb !important",
      },
  },
}));

const ProjectsListingPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const classes = useStyles();
  return (
    <section className="spokes-people-pagination">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="pagination">
              {totalPages > 1 && (
                <Pagination
                  classes={{ ul: classes.ul }}
                  count={totalPages}
                  page={currentPage}
                  color="primary"
                  renderItem={(item) => (
                    <PaginationItem
                      {...item} // eslint-disable-line react/jsx-props-no-spreading
                      onClick={() => onPageChange(item.page)}
                    />
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ProjectsListingPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default ProjectsListingPagination;
