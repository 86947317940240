/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import PropTypes from "prop-types";
import { uniqueId } from "lodash";
import { Link } from "gatsby";
import IsrLabel from "../../../IsrLabel/IsrLabel";
import "./ProjectItem.scss";

const ProjectItem = ({ ProjectData, setFilters, isStatic }) => {
  const { title } = ProjectData;
  const uri = isStatic ? ProjectData.uri : `/isr_projects/${ProjectData.link}/`;

  let researchCategories = null;
  if (isStatic)
    researchCategories = ProjectData.projectsCategories.nodes.map(
      (category) => (
        <span
          key={uniqueId("bp_cat_")}
          onClick={() =>
            setFilters({
              researchCategory: {
                label: category.name,
                value: category.databaseId,
              },
            })
          }
        >
          <IsrLabel variant="secondary" size="md" color="grey">
            {category.name}
          </IsrLabel>
        </span>
      )
    );
  else
    researchCategories = ProjectData.projectsCategories.map((category) => (
      <span
        key={uniqueId("bp_cat_")}
        onClick={() =>
          setFilters({
            researchCategory: {
              label: category.name,
              value: category.term_id,
            },
          })
        }
      >
        <IsrLabel variant="secondary" size="md" color="grey">
          {category.name}
        </IsrLabel>
      </span>
    ));

  const author = isStatic ? ProjectData.author.node.name : ProjectData.author;
  const crisisName = isStatic
    ? ProjectData.projectSingle.crisisName.title
    : ProjectData.crisisName.post_title;
  const crisisNameId = isStatic
    ? ProjectData.projectSingle.crisisName.databaseId
    : ProjectData.crisisName.ID;
  const projectStatus = isStatic
    ? ProjectData.projectSingle.projectStatus
    : ProjectData.projectStatus;
  const country = isStatic
    ? ProjectData.projectSingle.country[1]
    : ProjectData.country.label;
  const countryValue = isStatic
    ? ProjectData.projectSingle.country[0]
    : ProjectData.country.value;
  const { date } = ProjectData;
  const excerpt = isStatic
    ? ProjectData.pageExcerpt.pageExcerpt
    : ProjectData.pageExcerpt;
  return (
    <article className="project-item">
      {title && (
        <Link to={uri} title={title}>
          <h3>{title}</h3>
        </Link>
      )}
      <div className="project-categories">
        {crisisName && (
          <span
            onClick={() =>
              setFilters({
                crisisName: { label: crisisName, value: crisisNameId },
              })
            }
          >
            <IsrLabel variant="secondary" size="md" color="grey">
              {crisisName}
            </IsrLabel>
          </span>
        )}
        {projectStatus && (
          <span
            onClick={() =>
              setFilters({
                projectStatus: { label: projectStatus, value: projectStatus },
              })
            }
          >
            <IsrLabel variant="secondary" size="md" color="grey">
              {projectStatus}
            </IsrLabel>
          </span>
        )}
        {researchCategories}
        {country && (
          <span
            onClick={() =>
              setFilters({
                country: { label: country, value: countryValue },
              })
            }
          >
            <IsrLabel variant="secondary" size="md" color="grey">
              {country}
            </IsrLabel>
          </span>
        )}
      </div>
      <div className="article-body">
        <div>
          <p className="author">{author}</p>
          {/* <p className="location">{location}</p> */}
          {date && <p className="date">{date}</p>}
        </div>
        <div>
          <p
            className="excerpt"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: excerpt,
            }}
          />
        </div>
      </div>
    </article>
  );
};

ProjectItem.propTypes = {
  ProjectData: PropTypes.shape({
    projectStatus: PropTypes.string,
    crisisName: PropTypes.shape({
      post_title: PropTypes.string,
      ID: PropTypes.number,
    }),
    country: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    link: PropTypes.string,
    title: PropTypes.string.isRequired,
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          uri: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    uri: PropTypes.string,
    author: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        node: PropTypes.shape({ name: PropTypes.string.isRequired }),
      }),
    ]),
    date: PropTypes.string,
    tag: PropTypes.string,
    excerpt: PropTypes.string,
    slug: PropTypes.string,
    type: PropTypes.string,
    projectsCategories: PropTypes.oneOfType([
      PropTypes.shape({ nodes: PropTypes.array }),
      PropTypes.array,
    ]),
    projectSingle: PropTypes.shape({
      crisisName: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        databaseId: PropTypes.number,
      }),
      projectStatus: PropTypes.string,
      country: PropTypes.array,
    }),
    pageExcerpt: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ pageExcerpt: PropTypes.string }),
    ]),
  }).isRequired,
  setFilters: PropTypes.func.isRequired,
  isStatic: PropTypes.bool.isRequired,
};

export default ProjectItem;
