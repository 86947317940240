import React from "react";
import uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";
import ProjectItem from "../ProjectItem/ProjectItem";
import Spacer from "../../../Spacer/Spacer";

const ProjectListingSection = ({
  projects,
  setFilters,
  isLoading,
  isStatic,
}) => {
  if (isLoading)
    return (
      <div className="loader">
        <div className="lds-ring">
          <div />
          <div />
          <div />
          <div />
        </div>
        <p>Loading...</p>
      </div>
    );

  return (
    <div className="container">
      {projects instanceof Array && projects.length > 0 ? (
        projects.map((project) => (
          <ProjectItem
            key={uniqueId("project_")}
            ProjectData={project}
            setFilters={setFilters}
            isStatic={isStatic}
          />
        ))
      ) : (
        <h3 className="no-data">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-info-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </svg>
          There are no projects or the selected criteria don&apos;t match.
        </h3>
      )}
      <Spacer mobileSize={50} size={100} />
    </div>
  );
};

ProjectListingSection.propTypes = {
  projects: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  setFilters: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isStatic: PropTypes.bool.isRequired,
};
export default ProjectListingSection;
