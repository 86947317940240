import { useStaticQuery, graphql } from "gatsby";

const useProjectCategoriesAll = () => {
  const data = useStaticQuery(graphql`
    {
      allWpProjectsCategory {
        nodes {
          databaseId
          name
        }
      }
    }
  `);

  return data.allWpProjectsCategory.nodes.map((category) => ({
    label: category.name,
    value: category.databaseId,
  }));
};

export default useProjectCategoriesAll;
