import { useStaticQuery, graphql } from "gatsby";

const useFeaturedProjects = () => {
  const data = useStaticQuery(graphql`
    {
      wpPage(template: { templateName: { eq: "Page Projects Listing" } }) {
        projectsPage {
          featuredProjects {
            heading
            selectFeaturedProjects {
              selectProject {
                ... on WpISRProject {
                  title
                  uri
                  date(formatString: "MMMM DD, YYYY")
                  author {
                    node {
                      firstName
                      lastName
                      name
                    }
                  }
                  projectSingle {
                    country
                    crisisName {
                      ... on WpCrisis {
                        id
                        uri
                        title
                      }
                    }
                    projectStatus
                  }
                  projectsCategories {
                    nodes {
                      name
                      uri
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return data.wpPage.projectsPage.featuredProjects;
};

export default useFeaturedProjects;
